@import '../../common/styles/config.scss';

//================== LOCAL CONFIG =======================
   $separator-height: 5px;
   $footer-grid-row-gap: 13px;
   $footer-grid-row-gap-desktop: 19px;
   $footer-grid-gap: 50px;
   $footer-formal-text-gap: 30px;

   $footer-formal-font-size: 10px;
   $footer-main-font-size: 12px;

   $footer-mobile-logos-size: 80px;
   $footer-paytree-logo-margin-right: 15px;
//=======================================================

.page-footer-outer-wrapper {
   grid-area: footer;
}

.footer {
   grid-area: footer;
   background-color: $main-app-color;
   padding: 32px $mobile-page-margin 25px $mobile-page-margin;
   font-size: $footer-main-font-size;
   min-width: 330px;

   & p {
      margin: 0 ;
      font-weight: 400 !important;
   }
 
   //Tablets
   @media(min-width: $tablet-breakpoint){
      padding: 29px $tablet-page-margin 20px $tablet-page-margin;
   }

   //Desktop
   @media(min-width: $desktop-breakpoint){
      padding: 29px $desktop-page-margin 20px $desktop-page-margin;
   }
}

.separator{
   width: 100%;
   height: $separator-height;
   background: $main-app-gradient;
}

.footer-heading-logo{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-bottom: 36px;
}

.footer-line-after-logo{
   background-color: $main-darken-app-color;
   width: 100%;
   height: 1px;
   margin: -8px 0 0 15px;
   justify-self: flex-end;
}

.footer-formal-text-wrapper {
   color: $main-light-color;
   grid-area: formal-text;
   font-size: $footer-formal-font-size;
   text-align: justify;
   line-height: 12px;
}

.footer-text-wrapper {
   color: $secondary-app-color;
   align-items: center;
   display: grid;
   row-gap: $footer-grid-row-gap;
   grid-template-columns: 1fr 1fr 1fr 1fr; 
   grid-template-rows: 0.2fr 0.2fr 0.2fr 0.2fr $footer-grid-gap 0.2fr 0.2fr 0.2fr $footer-formal-text-gap 1fr; 
   grid-template-areas: 
    "heading-1 heading-1 heading-2 heading-2"
    "how-it-works how-it-works privacy-policy privacy-policy"
    "faq faq website-regulations website-regulations"
    "contact contact docs-to-download docs-to-download"
    "gap gap gap gap"
    "contact-heading contact-heading logo-heading-1 logo-heading-2"
    "phone phone logo-1 logo-2"
    "opening-hours opening-hours logo-1 logo-2"
    "formal-text-gap formal-text-gap formal-text-gap formal-text-gap"
    "formal-text formal-text formal-text formal-text"; 
   
   & p {
      font-size: $footer-main-font-size;
      font-weight: 400;
   }
   
   & a {
      font-size: $footer-main-font-size;
      font-weight: 400;
   }
   
   //Desktop and tablets  
   @media(min-width: 900px) {
      grid-template-columns: 0.2fr 0.2fr 0.2fr auto 0.2fr 0.2fr; 
      grid-template-rows: 30px 30px 30px 23px 1fr; 
      gap: 13px 30px;
      grid-template-areas: 
      "heading-1 heading-2 contact-heading . logo-heading-1 logo-heading-2"
      "how-it-works privacy-policy phone gap logo-1 logo-2"
      "faq website-regulations opening-hours gap logo-1 logo-2"
      "contact docs-to-download . gap . ."
      "formal-text-gap formal-text-gap formal-text-gap formal-text-gap formal-text-gap formal-text-gap"
      "formal-text formal-text formal-text formal-text formal-text formal-text"; 
   }

   @media(min-width: 1300px) {
      gap: 13px 55px;  
   }
}

// ***************************************
.footer-heading-1{
   grid-area: heading-1;
   font-weight: 700;
   white-space: nowrap;
}

.footer-heading-2{
   grid-area: heading-2;
   font-weight: 700;
   white-space: nowrap;
}

.how-it-works{
   grid-area: how-it-works;
   white-space: nowrap;
}

.faq{
   grid-area: faq;
   white-space: nowrap;
}

.contact{
   grid-area: contact;
   white-space: nowrap;
}

.privacy-policy{
   grid-area: privacy-policy;
   white-space: nowrap;
}

.website-regulations{
   grid-area: website-regulations;
   white-space: nowrap;
}

.docs-to-download{
   grid-area: docs-to-download;
   white-space: nowrap;
}

.gap{
   grid-area: gap;
}

.contact-heading{
   grid-area: contact-heading;
   font-weight: 700;
   white-space: nowrap;
}

.footer-phone-wrapper{
   grid-area: phone;
   display: flex;
   justify-content: flex-start;
   justify-self: flex-start;
   align-items: center;
   padding: 0;
   margin: 0;
   white-space: nowrap;
   
   & p {
      font-weight: 700 !important;
      margin-left: 5px;
   }
   //Desktop and tablets  
   @media(min-width: $tablet-breakpoint) {
      align-items: center;
      margin-bottom: 0;
   } 
}

.opening-hours{
   grid-area: opening-hours;
   justify-self: flex-start;
   white-space: nowrap;
}

.logo-heading-1{
   grid-area: logo-heading-1;
   width: calc($footer-mobile-logos-size + $footer-paytree-logo-margin-right);
   font-weight: 700 !important;
   justify-self: flex-start;
   align-self: center;
   white-space: nowrap;

   //Desktop and tablets  
   @media(min-width: 1100px) {
      width: calc(135px + $footer-paytree-logo-margin-right);
      justify-self: flex-end;
   } 
}  

.logo-heading-2{
   grid-area: logo-heading-2;
   width: $footer-mobile-logos-size;
   font-weight: 700 !important;
   justify-self: flex-start;
   align-self: center;
   white-space: nowrap;

   //Desktop and tablets  
   @media(min-width: 1100px) {
      width: 135px;
      justify-self: flex-end;
   }
}  

.logo-1{
   grid-area: logo-1;
   font-weight: 700 !important;
   justify-self: flex-start;
   width: $footer-mobile-logos-size;
   margin: 0 $footer-paytree-logo-margin-right 0 0;

   & img {
      width: 100%;
   }
   
   //Desktop and tablets  
   @media(min-width: 1100px) {
      width: 135px;
      justify-self: flex-end;
   } 
}  

.logo-2{
   grid-area: logo-2;
   font-weight: 700 !important;
   justify-self: flex-start;
   width: $footer-mobile-logos-size;

   & img {
      width: 100%;
      justify-self: flex-end;
   }
   
   //Desktop and tablets  
   @media(min-width: 1100px) {
     width: 135px;
     justify-self: flex-end;
   }      
}  

.formal-text-gap{
   grid-area: formal-text-gap;
   font-weight: 300;
}
// ***************************************

.footer-breakline{
   margin: 20px auto;
   background-color: $main-darken-app-color;
   height: 1px;
}

.footer-copyright{
   color: $main-light-color;
}