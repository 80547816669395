@import '../../common/styles/config.scss';

//================== LOCAL CONFIG =======================
   $page-logo-desktop-width: 140px;
   $nav-top-separator-height-mobile: 5px;
   $nav-top-separator-height-desktop: 9px;
   $nav-hamburger-breakpoint: 1000px;


   $mobile-margin-size-1:  30px;
   $desktop-margin-size-1: 30px;

   $desktop-margin-size-2: 15px;
//=======================================================

.page-navbar {
   grid-area: navbar;
   background-color: $page-color;
}

.page-navbar-outer-wrapper {
   padding: 0 $mobile-page-margin;
   height: calc($mobile-navbar-height - $nav-top-separator-height-mobile);

   //Tablets
   @media(min-width: $tablet-breakpoint){
      height: calc($desktop-navbar-height - $nav-top-separator-height-desktop);
      padding: 0 $tablet-page-margin;
   }

   //Desktop
   @media(min-width: $desktop-breakpoint){
      padding: 0 $desktop-page-margin;
   }  
}

.page-navbar-inner-wrapper {
   height: calc($mobile-navbar-height - $nav-top-separator-height-mobile);
   display: grid; 
   grid-template-columns: $page-logo-desktop-width auto min-content min-content; 
   grid-template-rows: 1fr; 
   grid-template-areas: 
     "page-logo gap phone burger-menu"; 
   align-items: center;
   justify-items: center;
   
   //Desktop and tablets
   @media(min-width: $nav-hamburger-breakpoint){
      height: calc($desktop-navbar-height - $nav-top-separator-height-desktop);
      grid-template-columns: $page-logo-desktop-width 1fr min-content min-content; 
      grid-template-rows: 1fr; 
      grid-template-areas: 
        "page-logo gap nav-links phone"; 
      align-items: center;
      justify-self: flex-start;
   }  
}


.navbar-separator {
   height: $nav-top-separator-height-mobile;

   //Desktop and tablets
   @media(min-width: $tablet-breakpoint){
      height: $nav-top-separator-height-desktop;
   } 
}

.navbar-links-wrapper {
   display: none;

   @media(min-width: $nav-hamburger-breakpoint){
      display: flex;
      grid-area: nav-links;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      justify-self: flex-end;
   }
}

.nav-links-item {
   padding: 0 0 0 $desktop-margin-size-1;
   font-size: 20px;
   color: $secondary-app-color;
   white-space: nowrap;
   
   &:hover {
      @include gradient-text;
      transition: all .25s;
   }
}


.page-logo {
   grid-area: page-logo;
   margin: 0 auto;
   
   & img {
      height: 25px;
      width: 140px;
      margin-top: 8px;
   }
}

.navbar-phone-wrapper {
   grid-area: phone;
   column-gap: $mobile-margin-size-1;
   display: flex;
   flex-wrap: nowrap;
   justify-content: flex-end;
   align-items: flex-end;
   font-size: 14px;
   font-weight: 700;
   white-space: nowrap;
   margin-left: 0px;

   @media(min-width: $tablet-breakpoint){
      margin-left: $desktop-margin-size-1;
      column-gap: $desktop-margin-size-2;
   }
   
   & p {
      line-height: 26px; //Taka sama wysokość jak ikony poniżej
      display: none;
   }

   & img {
      height: 26px; //Taka sama wysokość jak line-height powyżej
      width: auto;
   }
   
   @media(min-width: $nav-hamburger-breakpoint) {
      justify-self: flex-end;
      margin-right: 0px;

      & p {
         display: block;
         color: $main-app-color;
         margin: 0 !important;
         font-size: 16px;
      }

      & img {
         width: 20px;
      }
   }
}

// =================== HAMBURGER MENU STYLES ==========================

.mobile-navbar-hamburger-menu-wrapper {
   grid-area: burger-menu;
   -webkit-tap-highlight-color: transparent;
   margin-left: $mobile-margin-size-1;


   @media(min-width: $nav-hamburger-breakpoint) {
      display: none;
   }
}

.mobile-navbar-inner-menu-wrapper {
   position: absolute;
   z-index: 999;
   background-color: rgba($color: #FFF, $alpha: .95);
   top: $mobile-navbar-height;
   left: 0;
   right: 0;
   display: flex;
   flex-direction: column;
   text-align: center;
}

.mobile-menu-nav-links-item {
   padding: 15px;
   font-size: 24px;
   color: $secondary-app-color;
   font-weight: 500;
   border-bottom: 1px solid rgba($color: #9A989C, $alpha: .5);
   line-height: 35px;

   &:nth-child(1){ 
      border-top: 1px solid rgba($color: #9A989C, $alpha: .5);
   }
}

#navbar-hamburger-icon {
   width: 40px;
   height: 26px;
   position: relative;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   -webkit-transition: .5s ease-in-out;
   -moz-transition: .5s ease-in-out;
   -o-transition: .5s ease-in-out;
   transition: .5s ease-in-out;
   cursor: pointer;
   user-select: none !important;

   & span {
      display: block;
      position: absolute;
      height: 5px;
      width: 100%;
      background: $main-app-gradient;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
   }

   @media(min-width: $nav-hamburger-breakpoint) {
      display: none;
   }
   
}

#navbar-hamburger-icon span:nth-child(1) {
   top: 0px;
}
 
#navbar-hamburger-icon span:nth-child(2),#navbar-hamburger-icon span:nth-child(3) {
   top: 10px;
}
 
#navbar-hamburger-icon span:nth-child(4) {
   top: 20px;
}
 
#navbar-hamburger-icon.open span:nth-child(1) {
   top: 10px;
   width: 0%;
   left: 50%;
}
 
#navbar-hamburger-icon.open span:nth-child(2) {
   -webkit-transform: rotate(45deg);
   -moz-transform: rotate(45deg);
   -o-transform: rotate(45deg);
   transform: rotate(45deg);
}
 
#navbar-hamburger-icon.open span:nth-child(3) {
   -webkit-transform: rotate(-45deg);
   -moz-transform: rotate(-45deg);
   -o-transform: rotate(-45deg);
   transform: rotate(-45deg);
}
 
#navbar-hamburger-icon.open span:nth-child(4) {
   top: 9px;
   width: 0%;
   left: 50%;
}