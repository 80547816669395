:root {
/* ========= COLORS =========== */   
   --main-app-gradient: linear-gradient(90deg, #FB8B24 0%, #5F0F40 100%);
   --main-app-color: #F28A2A;
   --main-darken-app-color: #E36512;
   --secondary-app-color:  #5F1341;
   --main-error-color: #991921;
   --main-succes-color: #144D5C;
   --main-light-color: #FFF;
   --main-aside-text-color: #9A989C;
   --page-color: #FFF;
   --offert-slider-rail-box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
   --offert-active-thumb-box-shadow: 0px 0px 0px 10px rgba(242, 138, 42, 0.5);
   --loan-calculator-active-thumb-box-shadow: 0px 0px 0px 7px rgba(95, 19, 65, 0.35);

/* ========= FONTS =========== */
   --font-family: 'Lato', sans-serif;
   --aside-font-size: 14px;

/* ========= SIZES =========== */
   --button-height: 65px;
   --button-width: 325px;
   --desktop-page-margin: 15%;
   --tablet-page-margin: 8%;
   --mobile-page-margin: 15px;
   --tablet-page-height: 8%;

   --mobile-navbar-height: 55px;
   --desktop-navbar-height: 75px;
}