//========= COLORS ===========
  $main-app-gradient: var(--main-app-gradient); 
  $footer-boundary-line-color: var(--footer-boundary-line-color);    
  $main-app-loader-color: var(--main-app-loader-color);
  $main-app-color: var(--main-app-color);  
  $main-darken-app-color: var(--main-darken-app-color);             
  $main-darken-app-color-hover: var(--main-darken-app-color-hover);                        
  $secondary-app-color: var(--secondary-app-color);                       
  $button-outlined-secondary-color-hover: var(--button-outlined-secondary-color-hover);                       
  $main-error-color: var(--main-error-color);             
  $main-succes-color: var(--main-succes-color);             
  $main-light-color: var(--main-light-color);             
  $main-dropdown-option-hover: var(--main-dropdown-option-hover);             
  $main-dropdown-option-selected: var(--main-dropdown-option-selected);             
  $main-aside-text-color: var(--main-aside-text-color);             
  $page-color: var(--page-color);

//========= FONTS ===========
  $font-family:  var(--font-family);
  $aside-font-size:  var(--aside-font-size);

//========= SIZES ===========
$desktop-page-margin: var(--desktop-page-margin);
$mobile-page-margin: var(--mobile-page-margin);
$tablet-page-margin: var(--tablet-page-margin);

$desktop-bottom-page-margin: 100px;
$mobile-bottom-page-margin: 150px;

$desktop-navbar-height: var(--desktop-navbar-height);
$mobile-navbar-height: var(--mobile-navbar-height);

$mobile-loan-calculator-size: 490px;

$desktop-sections-gap: 50px;
$mobile-sections-gap: 60px; 

// ========= BREAKPOINTS ===========
$desktop-breakpoint: 1100px;
$tablet-breakpoint: 800px;


@mixin gradient-text {
  background-color: $page-color;
  background-image: $main-app-gradient;
  background-size: 100%;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-text-fill-color: transparent;
}

@mixin section-heading-text {
  font-size: 35px;
  color: $secondary-app-color;
  font-weight: 700;
  font-family: $font-family;
  width: 100%;
  text-align: center;
}

@mixin main-button-styles {
  border: none;
  border-radius: 35px;
  padding: 13px 60px;
  font-size: 32px;
  font-weight: 700;
  font-family: $font-family;
  cursor: pointer;
  display: flex;
  background: linear-gradient(260deg, $secondary-app-color, transparent) $secondary-app-color;
  font-family: inherit;
  color: $main-light-color;
  text-decoration: none;
  transition: background-color .20s ease-in;
  place-content: center;
  width: 100%;
  white-space: nowrap;

  //Tablet 
  @media(min-width: $tablet-breakpoint){
    width: auto;
  } 

  //Desktop 
  @media(min-width: $desktop-breakpoint){
    &:hover, &:focus {
      background-color: $main-app-color;
      color: $main-light-color;
    }
  }  
}

@mixin main-page-title-styles {
  width: calc(100% - (2 * $mobile-page-margin));
  margin: 0 auto;
  border: none;
  border-radius: 31px;
  padding: 0;
  display: flex;
  align-items: center;
  background: $secondary-app-color;
  color: $main-light-color;
  text-decoration: none;
  place-content: center;

  & h1 {
    font-size: 36px !important;
    font-family: $font-family;
    font-weight: 700;
    margin: 0;
    text-align: center;
    padding: 12px 0;

      //Tablet 
    @media(min-width: $tablet-breakpoint){
      & br {
        display: none;
      }
    } 
  }

  //Tablet 
  @media(min-width: $tablet-breakpoint){
    width: 580px;
    height: 100px;
    white-space: nowrap;
  } 
}