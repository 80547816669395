body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background-color: var(--page-color);
}

* {
  font-family: var(--font-family);
}

html {
  height: 100%;
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type=number] {
  -moz-appearance: textfield;
}

li{
  list-style-type: none;
}

#root {
}

*{
  font-family: var(--font-family);
}

a {
  text-decoration: none;
  color: var(--secondary-app-color); 
}

input[type=number]::-webkit-inner-spin-button { /* Wyłączenie strzałek w górę oraz w dół dla kontrolki type="number" */
  -webkit-appearance: none;
}

hr {
  border: none;
  margin: 0;
}