@import '../../common//styles/config.scss';

.page-layout-outer-wrapper {
   display: grid; 
   grid-template-columns: 1fr; 
   grid-template-rows: $mobile-navbar-height auto min-content; 
   gap: 0px 0px; 
   grid-template-areas: 
     "navbar"
     "page-content"
     "footer";

   //Desktop and tablets
   @media(min-width: 840px){
      grid-template-rows: $desktop-navbar-height auto min-content; 
   }  
}

.page-content-wrapper {
   grid-area: page-content;
   min-height: 50vh;
}